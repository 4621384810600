import { template } from "@ember/template-compiler";
import { service } from 'ember-primitives';
import Route from 'ember-route-template';
import Footer from 'vfc-client/components/footer';
import Header from 'vfc-client/components/header';
import NotificationContainer from 'vfc-client/components/ui/notification-container';
const isSimpleTemplate = (currentRoute1: string)=>{
    return [
        'login',
        'request-password',
        'change-password'
    ].includes(currentRoute1);
};
export default Route(template(`
    <div class="flex flex-col h-full">
      {{#let (service "router") as |router|}}
        {{#if (isSimpleTemplate router.currentRoute.name)}}
          <div
            class="flex flex-col items-center flex-1 w-full bg-repeat-x bg-bottom m-auto"
            style="background-image: url('/bg/mountain.png'); "
          >
            <header class="w-full max-w-[1148px] p-8 mx-auto">
              <a href="https://findecursocolegio.com/">
                <img src="/images/logo.webp" alt="Logo" />
              </a>
            </header>
            <main class="w-full flex flex-1 items-center justify-center">
              {{outlet}}
            </main>
          </div>
        {{else}}
          <Header class="bg-white" />
          <div class="flex justify-center flex-1 w-full bg-neutral-50">
            {{! optional left side navigation }}
            <div id="left-side-navigation"></div>
            <main class="flex flex-1 max-w-screen-xl">
              {{outlet}}
            </main>
          </div>
          <Footer class="pt-4" />
        {{/if}}
      {{/let}}
    </div>
    <NotificationContainer class="absolute z-50 top-10 right-0 p-5" />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
