import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dataFromEvent } from 'ember-primitives/components/form';
import RouteTemplate from 'ember-route-template';
import UserService from 'vfc-client/services/user-service';
interface RequestPasswordSignature {
    Element: HTMLDivElement;
}
let RequestPasswordComponent = class RequestPasswordComponent extends Component<RequestPasswordSignature> {
    @service
    router: RouterService;
    @service
    userService: UserService;
    @tracked
    passwordReset = false;
    @action
    async requestPassword(event1: Event) {
        event1.preventDefault();
        const formData1 = dataFromEvent<{
            email: string;
        }>(event1);
        this.userService.resetPassword(formData1).finally(()=>{
            this.passwordReset = true;
        });
    }
    static{
        template(`
    <div class="flex flex-1 items-center justify-center">
      <div
        class="flex flex-col items-center gap-5 sm:gap-12 p-5 sm:p-10 shadow-md bg-white rounded-2xl w-[90%] sm:w-[350px]"
        style="box-shadow: 0px 20px 40px 0px #0A11261A;"
      >
        <div class="w-full flex flex-col items-center">
          {{#if this.passwordReset}}
            <div class="text-center font-semibold">
              Se ha enviado un correo electrónico con las
              <br />
              instrucciones para restablecer lacontraseña.
            </div>
          {{else}}
            <form {{on "submit" this.requestPassword}} class="w-full">
              <div class="mb-3 text-lg font-bold text-gray-700 text-center">
                ¿Olvidaste tu contraseña?
              </div>
              <div class="mb-6">
                <label
                  class="text-neutral-op-72 font-semibold text-[14px] leading-[19.6px] mb-1.5"
                  for="password"
                >
                  Email
                  <input
                    required
                    class="w-full py-2 px-3 border border-neutral-300 rounded-lg text-neutral-op-64 text-[16px] leading-[24px]"
                    type="email"
                    name="email"
                    placeholder="user@example.com"
                  />
                </label>
              </div>
              <div class="flex items-center flex-col">
                <button class="w-full bg-accent2-500 rounded-full overflow-hidden" type="submit">
                  <span
                    class="w-full h-full bg-gradient-to-r from-[#EBFC9A] to-[#C4EB30] hover:bg-none px-3 py-2 block"
                  >
                    Reestablecer contraseña
                  </span>
                </button>
              </div>
            </form>
          {{/if}}
          <a
            href="/login"
            class="text-sm text-accent1-400 hover:text-accent1-600 cursor-pointer mt-4"
          >
            Volver a iniciar sesión
          </a>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(template(`<RequestPasswordComponent />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
