import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Intl from 'ember-intl/services/intl';
import PhEye from 'ember-phosphor-icons/components/ph-eye';
import PhEyeSlash from 'ember-phosphor-icons/components/ph-eye-slash';
import RouteTemplate from 'ember-route-template';
import { TrackedObject } from 'tracked-built-ins';
import type UserService from 'vfc-client/services/user-service';
import type { TODO } from 'vfc-client/utility-types';
interface LoginRouteSignature {
    Args: {
        model: void;
    };
}
let LoginRouteComponent = class LoginRouteComponent extends Component<LoginRouteSignature> {
    @service
    session: TODO;
    @service
    userService: UserService;
    @service
    intl: Intl;
    @tracked
    errorMessage?: string;
    @tracked
    passwordVisible = false;
    data = new TrackedObject<{
        email: string;
        password: string;
    }>();
    @action
    async onSubmit(event1: Event) {
        event1.preventDefault();
        try {
            await this.session.authenticate('authenticator:token', this.data.email, this.data.password);
        } catch (error1) {
            console.log(error1);
            this.errorMessage = this.intl.t('page.login.invalid-user-or-password');
        }
    }
    @action
    onInput({ currentTarget: formElement1 }: {
        currentTarget: EventTarget | null;
    }) {
        let formData1 = new FormData(formElement1 as HTMLFormElement);
        this.data = Object.fromEntries(formData1.entries()) as {
            email: string;
            password: string;
        };
    }
    @action
    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }
    static{
        template(`
    <div
      class="flex flex-col items-center gap-5 sm:gap-12 p-5 sm:p-10 shadow-md bg-white rounded-2xl w-[90%] sm:w-[350px]"
      style="box-shadow: 0px 20px 40px 0px #0A11261A;"
    >
      <form class="w-full" {{on "submit" this.onSubmit}} {{on "input" this.onInput}}>
        <div class="mb-6 text-xl text-neutral-900 font-bold text-center">
          Bienvenido a
          <br />
          Fin de Curso Colegio
        </div>
        <div class="mb-3 text-lg font-bold text-gray-700 text-center">
          Iniciar sesión
        </div>
        <div class="mb-4">
          <label
            class="text-neutral-op-72 font-semibold text-[14px] leading-[19.6px] mb-1.5"
            for="email"
          >
            Email
          </label>
          <input
            required
            class="w-full py-2 px-3 border border-neutral-300 rounded-lg text-neutral-op-64 text-[16px] leading-[24px]"
            type="text"
            name="email"
          />
        </div>
        <div class="mb-6">
          <label
            class="text-neutral-op-72 font-semibold text-[14px] leading-[19.6px] mb-1.5"
            for="password"
          >
            Contraseña
          </label>
          <div class="flex items-center">
            <input
              required
              class="w-full py-2 px-3 border border-neutral-300 rounded-lg text-neutral-op-64 text-[16px] leading-[24px]"
              type="{{if this.passwordVisible 'text' 'password'}}"
              name="password"
            />
            {{#if this.passwordVisible}}
              <PhEyeSlash
                class="-m-6 cursor-pointer"
                {{on "click" this.togglePasswordVisibility}}
              />
            {{else}}
              <PhEye class="-m-6 cursor-pointer" {{on "click" this.togglePasswordVisibility}} />
            {{/if}}
          </div>
        </div>
        <div class="flex items-center flex-col">
          <button class="w-full bg-accent2-500 rounded-full overflow-hidden" type="submit">
            <span
              class="w-full h-full bg-gradient-to-r from-[#EBFC9A] to-[#C4EB30] hover:bg-none px-3 py-2 block"
            >
              Iniciar sesión
            </span>
          </button>
        </div>
        <div class="flex items-center flex-col mt-4">
          <a
            href="/request-password"
            class="text-sm text-accent1-400 hover:text-accent1-600 cursor-pointer"
          >
            ¿Olvidaste tu contraseña?
          </a>
        </div>
        <div class="text-xs text-red-500 py-1 flex justify-center">
          {{this.errorMessage}}&#8203;
        </div>
      </form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(LoginRouteComponent);
