import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { HeadlessForm } from 'ember-headless-form';
import type IntlService from 'ember-intl/services/intl';
import PhEye from 'ember-phosphor-icons/components/ph-eye';
import PhEyeSlash from 'ember-phosphor-icons/components/ph-eye-slash';
import RouteTemplate from 'ember-route-template';
import { HeadlessFormWrapper } from 'vfc-client/components/headless-form-wrapper';
import UserService from 'vfc-client/services/user-service';
interface ChangePasswordSignature {
    Element: HTMLDivElement;
    Args: {
        token: string;
    };
}
let ChangePasswordComponent = class ChangePasswordComponent extends Component<ChangePasswordSignature> {
    @service
    router: RouterService;
    @service
    userService: UserService;
    @service
    intl: IntlService;
    @tracked
    passwordVisible = false;
    @tracked
    confirmPasswordVisible = false;
    validateInputNatively = (event1: Event)=>{
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = input1.validity;
        if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.custom.password-requirements'));
        }
    };
    validatePassword = ({ password: password1, confirmPassword: confirmPassword1 }: {
        password: string;
        confirmPassword: string;
    })=>{
        if (password1 !== confirmPassword1) {
            return {
                confirmPassword: [
                    {
                        type: 'emailConfirmationMatch',
                        value: confirmPassword1,
                        message: `Las contrasenas no coinciden`
                    }
                ]
            };
        }
    };
    @action
    async changePassword(formData1: FormData) {
        formData1.token = this.args.token;
        this.userService.changePassword(formData1).finally(()=>{
            this.router.transitionTo('login');
        });
    }
    @action
    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }
    @action
    toggleConfirmPasswordVisibility() {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
    static{
        template(`
    <div class="flex flex-1 items-center justify-center">
      <div
        class="flex flex-col items-center gap-5 sm:gap-12 p-5 sm:p-10 shadow-md bg-white rounded-2xl w-[90%] sm:w-[350px]"
        style="box-shadow: 0px 20px 40px 0px #0A11261A;"
      >
        <HeadlessFormWrapper as |wrapper|>
          <HeadlessForm
            class="w-full"
            @validate={{this.validatePassword}}
            @validateOn="focusout"
            @revalidateOn="input"
            @onSubmit={{this.changePassword}}
            as |form|
          >
            <div class="mb-3 text-lg font-bold text-gray-700 text-center">
              Recuperar contraseña
            </div>
            <div class="mb-6">
              <form.Field @name="password" as |field|>
                <div>
                  <field.Label
                    class="text-neutral-op-72 font-semibold text-[14px] leading-[19.6px] mb-1.5"
                  >
                    Nueva contraseña
                    <span class="text-red-500">
                      *
                    </span>
                  </field.Label>
                  <div class="flex items-center">
                    <field.Input
                      required
                      type="{{if this.passwordVisible 'text' 'password'}}"
                      {{on "invalid" this.validateInputNatively}}
                      class="w-full py-2 px-3 border border-neutral-300 rounded-lg text-neutral-op-64 text-[16px] leading-[24px]"
                      minlength="12"
                      maxlength="128"
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@\$!%*?&_\\-])[A-Za-z\\d@\$!%*?&_\\-]{12,}\$"
                    />
                    {{#if this.passwordVisible}}
                      <PhEyeSlash
                        class="-m-6 cursor-pointer"
                        {{on "click" this.togglePasswordVisibility}}
                      />
                    {{else}}
                      <PhEye
                        class="-m-6 cursor-pointer"
                        {{on "click" this.togglePasswordVisibility}}
                      />
                    {{/if}}
                  </div>
                  <field.Errors class="text-xs text-red-500" />
                </div>
              </form.Field>
            </div>
            <div class="mb-6">
              <form.Field @name="confirmPassword" as |field|>
                <div>
                  <field.Label
                    c
                    class="text-neutral-op-72 font-semibold text-[14px] leading-[19.6px] mb-1.5"
                  >
                    Repetir contraseña
                    <span class="text-red-500">
                      *
                    </span>
                  </field.Label>
                  <div class="flex items-center">
                    <field.Input
                      required
                      type="{{if this.confirmPasswordVisible 'text' 'password'}}"
                      {{on "invalid" this.validateInputNatively}}
                      class="w-full py-2 px-3 border border-neutral-300 rounded-lg text-neutral-op-64 text-[16px] leading-[24px]"
                    />
                    {{#if this.passwordVisible}}
                      <PhEyeSlash
                        class="-m-6 cursor-pointer"
                        {{on "click" this.toggleConfirmPasswordVisibility}}
                      />
                    {{else}}
                      <PhEye
                        class="-m-6 cursor-pointer"
                        {{on "click" this.toggleConfirmPasswordVisibility}}
                      />
                    {{/if}}
                  </div>
                  <field.Errors class="text-xs text-red-500" />
                </div>
              </form.Field>
            </div>
            <div class="flex items-center flex-col">
              <button class="w-full bg-accent2-500 rounded-full overflow-hidden" type="submit">
                <span
                  class="w-full h-full bg-gradient-to-r from-[#EBFC9A] to-[#C4EB30] hover:bg-none px-3 py-2 block"
                >
                  Actualizar contraseña
                </span>
              </button>

              <a
                href="/login"
                class="text-sm text-accent1-400 hover:text-accent1-600 cursor-pointer mt-4"
              >
                Volver a iniciar sesión
              </a>
            </div>
          </HeadlessForm>
        </HeadlessFormWrapper>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            token: string;
        };
    };
}>(template(`<ChangePasswordComponent @token="{{@model.token}}" />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
